import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import {
  IOrderABemerTrialFormInputs,
  IOrderABemerTrialPayload,
  IOrderABemerTrialSuccessResponse,
  IProduct,
} from '@bemer/types';
import { BemModuleOrderABemerTrial, IBemBlockContent } from '@bemer/ui-library';
import {
  getPartnerFinderDataFromStore,
  getSessionId,
  postOrderABemerTrialFormData,
} from '@bemer/middleware';
import TranslationContext from '../../context/translationContext';

export interface IFormWrapperProps {
  title?: string;
  legalCopy: IBemBlockContent;
  successPage: string;
  cancelPage: string;
  products: IProduct[];
}

const FormWrapper = ({
  title,
  legalCopy,
  products,
  successPage,
  cancelPage,
}: IFormWrapperProps): JSX.Element => {
  const { locale, T, countriesToLanguages } = useContext(TranslationContext);

  const [successUrl, setSuccessUrl] = useState(successPage);
  const [cancelUrl, setCancelUrl] = useState(cancelPage);
  const [formError, setFormError] = useState(undefined);

  const partnerFinderData = getPartnerFinderDataFromStore();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const baseUrl = location.origin;
    setSuccessUrl(new URL(`${successPage}`, baseUrl).toString());
    setCancelUrl(new URL(`${cancelPage}`, baseUrl).toString());
  }, []);

  const handleSubmit = async (data: IOrderABemerTrialFormInputs) => {
    const canAddDeliveryAddressDataToPayload =
      data.firstNameDeliveryAddress &&
      data.lastNameDeliveryAddress &&
      data.streetDeliveryAddress &&
      data.streetNumberDeliveryAddress &&
      data.postalCodeDeliveryAddress &&
      data.cityDeliveryAddress &&
      data.federalStateDeliveryAddress;

    const payload: IOrderABemerTrialPayload = {
      product: data.set,
      sessionId: getSessionId(),
      customer: {
        gender: data.gender,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        organisation: data.organization,
        language: locale.languageCode,
        phoneNo: data.phoneNo,
        taxId: data.taxId,
        distributorNo: data.distributorNo,
        email: data.email,
        birthDate: `${data.birthDateYear}-${data.birthDateMonth}-${data.birthDateDay}`,

        invoiceAddress: {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          organisation: data.organization,
          street: data.street,
          streetNumber: data.streetNumber,
          streetAdditional: data.streetAdditional,
          postalCode: data.postalCode,
          city: data.city,
          federalState: data.federalState,
          country: locale.countryCode,
        },
      },
      successUrl,
      cancelUrl,
    };

    if (canAddDeliveryAddressDataToPayload) {
      payload.customer.deliveryAddress = {
        firstName: data.firstNameDeliveryAddress || '',
        middleName: data.middleNameDeliveryAddress || '',
        lastName: data.lastNameDeliveryAddress || '',
        organisation: data.organizationDeliveryAddress || '',
        street: data.streetDeliveryAddress || '',
        streetNumber: data.streetNumberDeliveryAddress || '',
        streetAdditional: data.streetAdditionalDeliveryAddress || '',
        postalCode: data.postalCodeDeliveryAddress || '',
        city: data.cityDeliveryAddress || '',
        federalState: data.federalStateDeliveryAddress || '',
        country: locale.countryCode,
      };
    }

    try {
      const response = (await postOrderABemerTrialFormData(
        payload,
        locale,
        T
      )) as IOrderABemerTrialSuccessResponse;

      if (response.paymentType === 'N4CHECKOUT') {
        window.location.href = response.checkoutUrl;
        return;
      }

      navigate(successPage);
    } catch (error) {
      setFormError(error);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <BemModuleOrderABemerTrial
      title={title}
      products={products}
      T={T}
      partnerFinderData={partnerFinderData}
      countriesToLanguages={countriesToLanguages}
      legalCopy={legalCopy}
      formError={formError}
      onSubmit={handleSubmit}
      locale={locale}
    />
  );
};

export default FormWrapper;
