import {
  buildCountriesToLanguages,
  buildLocale,
  getPartnerFinderDataFromStore,
} from '@bemer/middleware';
import {
  IGraphqlSiteSettings,
  IGraphqlSupportedLanguage,
  IGraphqlTranslation,
  IGraphqlTranslations,
  IProduct,
  IPropsWebAppPages,
  TProcessResultIcon,
} from '@bemer/types';
import {
  BemBlockContent,
  BemButton,
  BemHeadline,
  BemModuleTextWithToc,
  BemModuleProcessResult,
  BemModuleProductBenefits,
  BemModuleProductDetailsBox,
  BemModuleProductFacts,
  BemModuleProductHero,
  BemModuleTitle,
  BemPage,
  BemTextWithImage,
  IBemBlockContent,
  TButtonVariant,
  TImagePosition,
} from '@bemer/ui-library';
import { graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import Footer from '../components/Footer';
import FormWrapper from '../components/Form/FormWrapper';
import Header from '../components/Header/HeaderWrapper';
import AppHelmet from '../components/Helmet/Helmet';
import TranslationContext from '../context/translationContext';

interface IPageContent {
  _key: string;
  _type: string;
  imagePosition?: TImagePosition;
  Image?: any;
  title?: string;
  subtitle?: string;
  productDetails?: any[];
  productFacts?: any;
  sections: any;
  productBenefits?: any;
  _rawCopy?: any;
  copy?: any;
  icon: TProcessResultIcon;
  button: any;
  legalCopy: IBemBlockContent;
  products?: IProduct[];
  successPage: {
    slug: {
      current: string;
    };
  };
  cancelPage: {
    slug: {
      current: string;
    };
  };
}

interface IButton {
  label: string;
  target: any;
  type: TButtonVariant;
}

interface IHeroComponent {
  _key: string;
  _type: string;
  name: string;
  price?: number;
  pricePrefixLabel?: string | null;
  priceSuffixLabel?: string | null;
  currency: string;
  button?: IButton;
  image?: any;
}

interface ISanityPage {
  id: string;
  title?: string;
  theme?: string;
  hero?: {
    heroComponent: IHeroComponent[];
  };
  pageContent?: IPageContent[];
}

interface ISanityTranslationsEdges {
  node: IGraphqlTranslations;
}

interface ISanitySupportedLanguagesEdges {
  node: IGraphqlSupportedLanguage;
}
interface IAllSanitySiteSettingsEdges {
  node: IGraphqlSiteSettings;
}

interface IData {
  sanityPage: ISanityPage;
  allSanitySiteSettings: {
    edges: IAllSanitySiteSettingsEdges[];
  };
  allSanityTranslations: {
    edges: ISanityTranslationsEdges[];
  };
  allSanitySupportedLanguages: {
    edges: ISanitySupportedLanguagesEdges[];
  };
}

interface IPropsPageTemplate extends IPropsWebAppPages {
  data: IData;
}

const PageTemplate = ({
  data,
  pageContext,
}: IPropsPageTemplate): JSX.Element | null => {
  const { pageContent = [], hero = { heroComponent: [] } } = data.sanityPage;
  const locale = buildLocale(pageContext.locale, pageContext.currency);

  // Check if the current page can be displayed
  const partnerFinderData = getPartnerFinderDataFromStore();
  const partnerHandleSettings = data.allSanitySiteSettings.edges?.find(
    (edge) => edge.node.language.localeIdentifier === locale.id
  )?.node;

  const partnerLevel =
    !!partnerFinderData?.partnerData?.level &&
    parseInt(partnerFinderData.partnerData.level, 10);

  const errorPageSlug =
    partnerHandleSettings?.partnerHandleErrorPage?.slug.current;

  // Exclude partners from restricted countries
  const isCountryRestricted =
    !!partnerFinderData?.finderData?.countryCode &&
    ["US", "CA"].includes(partnerFinderData.finderData.countryCode)

  // This is really ugly. Let's move such checks in a global "page access control".
  if (
    errorPageSlug &&
    typeof window !== 'undefined' &&
    !window.location.href.endsWith(errorPageSlug)
  ) {
    if ((partnerHandleSettings?.isPartnerHandleRequired &&
        partnerLevel < (partnerHandleSettings?.minRequiredPartnerLevel || 0) ||
        isCountryRestricted)) {
      navigate(errorPageSlug);
      return null;
    }
  }

  const filteredTranslations = data.allSanityTranslations.edges.filter(
    (edge: ISanityTranslationsEdges) =>
      edge.node.language.localeIdentifier === locale.id
  )[0];

  const translate = (
    translationKey: string,
    isMissingTranslationAnError = true
  ): string =>
    filteredTranslations?.node?.translation.filter(
      (translation: IGraphqlTranslation) =>
        translation.translationKey === translationKey
    )[0]?.value ||
    (isMissingTranslationAnError
      ? `TRANSLATION MISSING FOR FORM.${translationKey} IN ${locale.id}`
      : '');

  const countriesToLanguages = buildCountriesToLanguages(
    data.allSanitySupportedLanguages.edges.map(
      (edge: ISanitySupportedLanguagesEdges) => edge.node
    )
  );

  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <TranslationContext.Provider
      value={{
        locale,
        T: translate,
        countriesToLanguages,
        currency: locale.currency,
      }}
    >
      {/* TODO: should the theme handling go into BemPage? */}
      {/* <BemThemeWrapper key={id} appearance={theme === 'default' ? '' : theme}> */}
      <BemPage>
        <AppHelmet />
        <Header setHeaderHeight={setHeaderHeight} />

        {hero?.heroComponent?.map((theHero) => {
          switch (theHero._type) {
            case 'moduleHeroProductHero': {
              const {
                name,
                price,
                pricePrefixLabel,
                priceSuffixLabel,
                button,
                image,
              } = theHero;

              const formattedAmount = price
                ? new Intl.NumberFormat(locale.bcp47Id, {
                    style: 'currency',
                    currency: locale.currency || 'EUR',
                  }).format(price)
                : undefined;

              return (
                <BemModuleProductHero
                  key={theHero._key}
                  topOffset={headerHeight}
                  button={
                    button && (
                      <BemButton
                        variant={button.type}
                        onClick={() => navigate(button.target.slug.current)}
                        testId="product__hero__button"
                      >
                        {button.label}
                      </BemButton>
                    )
                  }
                  image={image}
                  name={name}
                  price={formattedAmount}
                  pricePrefixLabel={pricePrefixLabel}
                  priceSuffixLabel={priceSuffixLabel}
                />
              );
            }
            default: {
              return (
                <div key={theHero._key}>
                  <h2>{theHero._type}</h2>
                  <pre>{JSON.stringify(hero, null, 2)}</pre>
                </div>
              );
            }
          }
        })}

        {pageContent.map(({ _type, _key, ...contentElement }) => {
          switch (_type) {
            case 'moduleTitle': {
              const { title = '', subtitle } = contentElement;
              return (
                <BemModuleTitle key={_key} title={title} subtitle={subtitle} />
              );
            }
            case 'moduleProductFacts': {
              const { title = '', productFacts } = contentElement;
              return (
                <BemModuleProductFacts
                  key={_key}
                  title={title}
                  productFacts={productFacts}
                />
              );
            }
            case 'moduleTextWithToc': {
              const { title = '', sections } = contentElement;
              return (
                <BemModuleTextWithToc
                  key={_key}
                  title={title}
                  sections={sections}
                />
              );
            }
            case 'moduleProductDetailsWrapper': {
              const { title = '', productDetails = [] } = contentElement;
              return (
                <BemModuleProductDetailsBox key={_key} title={title}>
                  {productDetails.map((detail: any) => (
                    <BemTextWithImage
                      key={detail._key}
                      image={detail.image}
                      imagePosition={detail.imagePosition}
                      rawSx={{
                        pb: '128px',
                      }}
                    >
                      <BemHeadline
                        rank="h2"
                        rawSx={{
                          mb: '30px',
                        }}
                      >
                        {detail.title}
                      </BemHeadline>
                      <BemBlockContent blocks={detail.copy} />
                    </BemTextWithImage>
                  ))}
                </BemModuleProductDetailsBox>
              );
            }
            case 'moduleProductBenefits': {
              const { productBenefits } = contentElement;

              // graphql returns a gatsby image which needs
              // a minor change is structure for now
              const newProductBenefits = productBenefits.map((benefit: any) => {
                return {
                  ...benefit,
                  image: benefit?.image,
                };
              });

              return (
                <BemModuleProductBenefits
                  key={_key}
                  productBenefits={newProductBenefits}
                />
              );
            }
            case 'moduleBemerTest': {
              const {
                title,
                legalCopy,
                products = [],
                cancelPage: {
                  slug: { current: cancelPage },
                },
                successPage: {
                  slug: { current: successPage },
                },
              } = contentElement;
              return (
                <FormWrapper
                  key={_key}
                  title={title}
                  legalCopy={legalCopy}
                  successPage={successPage}
                  cancelPage={cancelPage}
                  products={products}
                />
              );
            }
            case 'moduleIconWithText': {
              const { title = '', icon, copy, button } = contentElement;
              return (
                <BemModuleProcessResult
                  key={_key}
                  iconName={icon}
                  title={title}
                  copy={copy}
                  button={
                    button && (
                      <BemButton
                        variant={button.type}
                        onClick={() => navigate(button.target.slug.current)}
                        testId="button__process__result"
                      >
                        {button.label}
                      </BemButton>
                    )
                  }
                />
              );
            }

            default:
              return (
                <div key={_key}>
                  <h2>{_type}</h2>
                  <pre>{JSON.stringify(contentElement, null, 2)}</pre>
                </div>
              );
          }
        })}
        <Footer />
      </BemPage>
    </TranslationContext.Provider>
  );
};

export default PageTemplate;

export const query = graphql`
  query($id: String!) {
    allSanitySupportedLanguages(
      filter: { active: { eq: true } }
      sort: { fields: country }
    ) {
      edges {
        node {
          country
          localeIdentifier
          language
          currency
        }
      }
    }

    allSanityTranslations(filter: { namespace: { eq: "Form" } }) {
      edges {
        node {
          id
          language {
            localeIdentifier
          }
          namespace
          translation {
            value
            translationKey
          }
        }
      }
    }

    allSanitySiteSettings {
      edges {
        node {
          language {
            localeIdentifier
          }
          isPartnerHandleRequired
          minRequiredPartnerLevel
          partnerHandleErrorPage {
            slug {
              current
            }
          }
        }
      }
    }

    sanityPage(id: { eq: $id }) {
      id
      title
      theme
      language {
        localeIdentifier
      }

      hero {
        heroComponent {
          ... on SanityModuleHeroProductHero {
            _key
            _type
            name
            price
            pricePrefixLabel
            priceSuffixLabel
            productID
            button {
              target {
                slug {
                  current
                }
              }
              label
              type
            }
            image {
              asset {
                fluid(maxWidth: 1280) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          ... on SanityModuleHeroSimpleHero {
            _key
            _type
          }
        }
      }

      pageContent {
        ... on SanityModuleTextWithToc {
          _key
          _type
          title
          sections {
            key: _key
            _type
            copy: _rawCopy
          }
        }
        ... on SanityModuleBemerTest {
          _key
          _type
          title
          legalCopy: _rawLegalCopy(resolveReferences: { maxDepth: 10 })
          products {
            productName
            productValue
            productPrice
          }
          cancelPage {
            slug {
              current
            }
          }
          successPage {
            slug {
              current
            }
          }
        }
        ... on SanityModuleIconWithText {
          _key
          _type
          icon
          title
          copy: _rawCopy(resolveReferences: { maxDepth: 10 })
          button {
            label
            type
            target {
              slug {
                current
              }
            }
          }
        }

        ... on SanityModuleProductFacts {
          _key
          _type
          title
          productFacts {
            title
            copy: _rawCopy
          }
        }

        ... on SanityModuleProductBenefits {
          _key
          _type
          productBenefits {
            copy: _rawCopy
            title
            image {
              asset {
                fluid(maxWidth: 200) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }

        ... on SanityModuleProductDetailsWrapper {
          _key
          _type
          title
          productDetails {
            ... on SanityModuleImageWithText {
              _type
              title
              imagePosition
              copy: _rawCopy
              image {
                asset {
                  url
                  fluid(maxWidth: 724) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
              _key
            }
          }
        }

        ... on SanityModuleTitle {
          _key
          _type
          subtitle
          title
        }
      }
    }
  }
`;
