import React, { useContext, useEffect, useRef } from 'react';
import { graphql, navigate, StaticQuery } from 'gatsby';
import { BemHeader } from '@bemer/ui-library';
import { IGraphqlSiteSettings } from '@bemer/types';
import TranslationContext from '../../context/translationContext';

interface ISanitySiteSetting {
  node: IGraphqlSiteSettings;
}

interface IPropsHeaderWrapper {
  setHeaderHeight: (height: number) => void;
}

const HeaderWrapper = ({
  setHeaderHeight,
}: IPropsHeaderWrapper): JSX.Element => {
  const { locale, T, countriesToLanguages } = useContext(TranslationContext);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setNewHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current?.clientHeight);
      }
    };
    setNewHeight();

    window.addEventListener('resize', setNewHeight);

    return () => window.removeEventListener('resize', setNewHeight);
  }, [headerRef]);

  return (
    <StaticQuery
      query={graphql`
        query languageQuery {
          dataset: sitePlugin(name: { eq: "gatsby-source-sanity" }) {
            id
            pluginOptions {
              dataset
            }
          }

          allSanitySiteSettings {
            edges {
              node {
                localeSwitchTitle
                language {
                  localeIdentifier
                }
                header {
                  contactLabel
                  contactPhoneNumber
                }
              }
            }
          }
        }
      `}
      render={({
        allSanitySiteSettings: { edges: settings },
        dataset: {
          pluginOptions: { dataset },
        },
      }) => {
        const localeSettings = settings.filter(
          ({ node: setting }: ISanitySiteSetting) =>
            setting.language.localeIdentifier === locale.id
        )?.[0]?.node;

        const { localeSwitchTitle: title, header: contact } = localeSettings;

        return (
          <BemHeader
            locale={locale}
            ref={headerRef}
            env={dataset}
            T={T}
            countriesToLanguages={countriesToLanguages}
            localeSelectorData={{
              onSwitch: (newLocale) => navigate(`/${newLocale}/`),
              title,
            }}
            contact={contact}
          />
        );
      }}
    />
  );
};

export default HeaderWrapper;
